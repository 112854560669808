import React from 'react';
import styled from 'styled-components';

const Menu = styled.main`
  padding: 15px 30px;
  max-width: 600px;
  margin: 0 auto;

  .menu-title {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 35px;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 25px;
  padding-bottom: 5px;

  border-bottom: 1px dotted #555;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);

  .item-title {
    margin-right: 12px;

    span {
      font-size: 0.875rem;
      font-weight: 400;
      background-color: #555;
      color: #f6f6f6;
      padding: 1px 4px;
      border-radius: 3px;
    }
  }

  .item-desc {
    font-size: 0.875rem;
    color: #f07f75;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .item-price-wrraper {
    display: flex;
  }

  .item-price {
    margin-right: 15px;

    @media (max-width: 375px) {
      margin-right: 10px;
    }

    span {
      font-size: 0.813rem;
      font-weight: 400;
      color: #f07f75;
      text-transform: uppercase;
    }
  }
`;

const Combos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 30px;

  .combo-item {
    box-shadow: 0 0 0 1px black;
    background-color: #333333;
    border-radius: 12px;
    padding: 10px;
    color: #f5f5f5;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    color: #ff8c01;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 10px;
    color: #ccc;
  }

  .unidades {
    /* background-color: #ff8c01; */
    font-style: italic;
  }

  .price {
    font-size: 1.8rem;
    margin-top: 10px;
    margin-bottom: 0;

    span {
      font-size: 0.875rem;
    }
  }
`;

const Esfihas = () => (
  <Menu className="menu">
    <h1 className="menu-title">Combos Esfihas</h1>

    <Combos>
      <div className="combo-item">
        <h3>Combo 1</h3>
        <p>4 Carne + 4 Queijo</p>

        <p className="unidades">8 Unidades</p>

        <h2 className="price">
          <span>R$</span> 35,00
        </h2>
      </div>

      <div className="combo-item">
        <h3>Combo 2</h3>
        <p>4 Carne + 4 Queijo + 4 Frango</p>

        <p className="unidades">12 Unidades</p>

        <h2 className="price">
          <span>R$</span> 49,00
        </h2>
      </div>

      <div className="combo-item">
        <h3>Combo 3</h3>
        <p>5 Carne + 5 Queijo + 5 Calabresa</p>

        <p className="unidades">15 Unidades</p>

        <h2 className="price">
          <span>R$</span> 59,00
        </h2>
      </div>

      <div className="combo-item">
        <h3>Combo 4</h3>
        <p>6 Carne + 6 Queijo + 6 Calabresa</p>

        <p className="unidades">18 Unidades</p>

        <h2 className="price">
          <span>R$</span> 69,00
        </h2>
      </div>
    </Combos>

    <h1 className="menu-title">Esfihas</h1>

    <Item>
      <h4 className="item-title">2 Queijos</h4>

      <h4 className="item-price">6,00</h4>
    </Item>

    <Item>
      <h4 className="item-title">Bacon</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Pepperone</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Pizza</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Frango com Requeijão</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Carne com Queijo</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Atum</h4>

      <h4 className="item-price">7,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Milho</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Calabresa</h4>

      <h4 className="item-price">4,25</h4>
    </Item>

    <Item>
      <h4 className="item-title">Calabresa com Requeijão</h4>

      <h4 className="item-price">6,00</h4>
    </Item>

    <Item>
      <h4 className="item-title">Brócolis com Queijo</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Carne</h4>

      <h4 className="item-price">4,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Palmito</h4>

      <h4 className="item-price">6,00</h4>
    </Item>

    <Item>
      <h4 className="item-title">Queijo</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    <Item>
      <h4 className="item-title">Franbacon</h4>

      <h4 className="item-price">7,00</h4>
    </Item>

    <Item>
      <h4 className="item-title">Carne com Cheddar</h4>

      <h4 className="item-price">6,00</h4>
    </Item>

    <Item>
      <h4 className="item-title">Philadelphia</h4>

      <h4 className="item-price">6,50</h4>
    </Item>

    {/* <Item>
      <h4 className="item-title">Rúcula com Tomate Seco</h4>

      <h4 className="item-price">6,50</h4>
    </Item> */}

    {/* <Item>
      <h4 className="item-title">Carne Seca</h4>

      <h4 className="item-price">7,00</h4>
    </Item> */}

    <h1 className="menu-title">Esfihas Doces</h1>

    <Item>
      <h4 className="item-title">Chocolate</h4>

      <h4 className="item-price">6,00</h4>
    </Item>

    {/* <Item>
      <h4 className="item-title">Chocolate Branco com Morango</h4>

      <h4 className="item-price">6,50</h4>
    </Item> */}

    <Item>
      <h4 className="item-title">Doce de Leite com Confete</h4>

      <h4 className="item-price">5,50</h4>
    </Item>

    {/* <Item>
      <h4 className="item-title">Brigadeiro com Confete</h4>

      <h4 className="item-price">6,50</h4>
    </Item> */}

    <Item>
      <h4 className="item-title">Chocolate Branco com Confete</h4>

      <h4 className="item-price">6,00</h4>
    </Item>

    <Item>
      <h4 className="item-title">Chocolate Ouro Branco</h4>

      <h4 className="item-price">6,00</h4>
    </Item>

    <Item>
      <h4 className="item-title">Chocolate com Confete</h4>

      <h4 className="item-price">6,00</h4>
    </Item>

    {/* <Item>
      <h4 className="item-title">Chocolate com Morango</h4>

      <h4 className="item-price">6,50</h4>
    </Item> */}
  </Menu>
);

export default Esfihas;
