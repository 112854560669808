import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { PizzaSlice } from 'styled-icons/fa-solid/PizzaSlice';
import { Beer } from 'styled-icons/typicons/Beer';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Esfihas from '../components/Esfihas/Esfihas';

const StyledAppBar = styled(AppBar)`
  background-color: #e94b3c;
  color: #ffffff;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #ffffff;
    height: 3px;
  }

  .MuiTabs-scrollButtons {
    color: #ffffff;
  }
`;

const Menu = styled.main`
  padding: 15px 30px;
  max-width: 600px;
  margin: 0 auto;

  .menu-title {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .item-row {
    display: flex;
    justify-content: space-between;

    .item-title {
      margin-right: 12px;
    }
  }
`;

const Item = styled.div`
  margin-bottom: ${props => (props.margin15 ? '15px' : '25px')};
  padding-bottom: ${props => (props.margin15 ? '0' : '25px')};

  border-bottom: 1px dotted #555;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);

  .item-title {
    span {
      font-size: 0.875rem;
      font-weight: 400;
      background-color: #555;
      color: #f6f6f6;
      padding: 1px 4px;
      border-radius: 3px;
    }
  }

  .item-desc {
    font-size: 0.875rem;
    color: #f07f75;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .item-price-wrraper {
    display: flex;
  }

  .item-price {
    margin-right: 15px;

    @media (max-width: 375px) {
      margin-right: 10px;
    }

    span {
      font-size: 0.813rem;
      font-weight: 400;
      color: #f07f75;
      text-transform: uppercase;
    }
  }
`;

const IndexPage = () => {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <Layout>
      <SEO
        title="Cardápio"
        keywords={[
          `pizzas`,
          `pizzas doces`,
          `esfihas salgadas e doces`,
          `depósito de bebidas`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
          `pizza delivery`,
        ]}
      />

      <StyledAppBar
        position="sticky"
        color="default"
        square={true}
        elevation={1}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="Pizzas" />
          <Tab label="Pizzas Doce" />
          <Tab label="Esfihas" />
          <Tab label="Bebidas" />
        </StyledTabs>
      </StyledAppBar>

      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        animateHeight
      >
        <Menu className="menu">
          <h1 className="menu-title">Pizzas</h1>

          <Item>
            <h4 className="item-title">Alho</h4>

            <p className="item-desc">
              Molho, alho frito, muçarela, parmesão, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 43,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 53,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 72,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Americana</h4>

            <p className="item-desc">
              Molho, milho, ervilha, palmito, bacon, cebola, presunto, ovo,
              muçarela, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 38,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 53,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 72,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Atum</h4>

            <p className="item-desc">
              Molho, atum, milho, cebola, muçarela, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 44,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 60,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 82,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Bacon</h4>

            <p className="item-desc">
              Molho, bacon, cebola, muçarela, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 43,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 58,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 76,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Baiana</h4>

            <p className="item-desc">
              Molho, calabresa moída, pimenta, muçarela, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 43,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 58,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 73,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Brasileira <span>Novidade</span>
            </h4>

            <p className="item-desc">
              Molho, muçarela, presunto, catupiry, azeitonas fatiadas, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 40,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 53,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 69,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Brócolis</h4>

            <p className="item-desc">
              Molho, brócolis, alho, muçarela, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 38,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 53,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 68,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Calabresa</h4>

            <p className="item-desc">
              Molho, muçarela, calabresa fatiada, cebola, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 38,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 55,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 70,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Calabresa 2 <span>Novidade</span>
            </h4>

            <p className="item-desc">Molho, calabresa, cebola, orégano.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 35,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 42,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 58,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Champignon</h4>

            <p className="item-desc">
              Molho, ervilha, palmito, cebola, champignon, presunto, ovo,
              muçarela, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 41,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 55,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 70,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Corn and Bacon <span>Novidade</span>
            </h4>

            <p className="item-desc">Molho, muçarela, milho, bacon, orégano.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 39,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 52,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 66,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Frango</h4>

            <p className="item-desc">
              Molho, frango desfiado, milho, muçarela, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 37,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 52,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 71,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Frango 2 <span>Novidade</span>
            </h4>

            <p className="item-desc">
              Molho, frango, milho, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 35,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 47,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 69,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Franbacon <span>Novidade</span>
            </h4>

            <p className="item-desc">
              Molho, muçarela, frango, milho, bacon, cheddar, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 45,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 63,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 85,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Gratinado</h4>

            <p className="item-desc">
              Molho, muçarela, parmesão, rodelas de tomate, catupiry,
              manjericão.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 43,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 56,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 71,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Iracema <span>Novidade</span>
            </h4>

            <p className="item-desc">
              Molho, muçarela, lombo, milho, bacon, cheddar, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 45,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 63,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 87,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Lombo</h4>

            <p className="item-desc">
              Molho, muçarela, lombo, cebola, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 39,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 53,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 71,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Margarita</h4>

            <p className="item-desc">
              Molho, muçarela, rodelas de tomate, catupiry, manjericão.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 40,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 51,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 65,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Mexicana</h4>

            <p className="item-desc">
              Molho, bacon, alho frito, pimentão, calabresa fatiada, muçarela,
              parmesão, tomate, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 43,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 56,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 80,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Milho</h4>

            <p className="item-desc">
              Molho, milho, muçarela, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 37,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 49,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 65,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Mineira</h4>

            <p className="item-desc">
              Molho, linguiça caipira, bacon, cebola, muçarela, parmesão,
              orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 40,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 53,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 72,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Moda da Casa 1</h4>

            <p className="item-desc">
              Molho, brócolis, bacon, alho frito, lombo, muçarela, catupiry,
              orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 45,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 59,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 75,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Moda da Casa 2</h4>

            <p className="item-desc">
              Molho, bacon, alho frito, lombo, muçarela, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 41,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 53,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 71,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Moderna</h4>

            <p className="item-desc">
              Molho, atum, brócolis, palmito, cebola, muçarela, rodelas de
              tomate, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 44,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 61,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 82,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Mussarela</h4>

            <p className="item-desc">
              Molho, muçarela, rodelas de tomate, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 37,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 52,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 65,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Palmito</h4>

            <p className="item-desc">
              Molho, palmito, muçarela, provolone, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 43,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 57,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 73,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Peito de peru</h4>

            <p className="item-desc">
              Molho, muçarela, palmito, peito de peru, cebola, rodelas de
              tomate, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 46,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 65,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 82,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Philadelphia <span>Novidade</span>
            </h4>

            <p className="item-desc">
              Molho, muçarela, peito de peru, tomate, cream cheese, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 45,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 60,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 80,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Pepperoni <span>Novidade</span>
            </h4>

            <p className="item-desc">Molho, muçarela,Pepperoni, orégano.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 41,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 56,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 73,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Pizza da Casa</h4>

            <p className="item-desc">
              Molho, carne seca, milho, cebola, muçarela, rodelas de tomate,
              catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 46,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 65,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 90,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Pizzaiolo</h4>

            <p className="item-desc">
              Molho, ervilha, palmito, bacon, cebola, muçarela, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 38,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 49,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 65,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Portuguesa</h4>

            <p className="item-desc">
              Molho, ervilha, bacon, presunto, calabresa fatiada, muçarela,
              catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 43,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 59,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 75,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Primavera</h4>

            <p className="item-desc">
              molho, milho, lombo, muçarela, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 41,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 53,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 70,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Presunto e queijo</h4>

            <p className="item-desc">
              Molho, muçarela, presunto, cebola, tomate, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 36,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 48,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 64,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">4 Queijos</h4>

            <p className="item-desc">
              Molho, muçarela, parmesão, provolone, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 44,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 59,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 73,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">5 Queijos</h4>

            <p className="item-desc">
              Molho, muçarela, parmesão, provolone, gorgonzola, rodelas de
              tomate, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 47,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 64,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 80,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Rúcula com Tomate Seco <span>Novidade</span>
            </h4>

            <p className="item-desc">
              molho, muçarela, rúcula, tomate seco, catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 40,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 56,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 72,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Suprema <span>Novidade</span>
            </h4>

            <p className="item-desc">
              Molho, muçarela, mix de carne bovina, pepperoni, pimentão,
              parmesão, orégano, azeitonas fatiadas.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 44,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 55,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 73,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Toscana</h4>

            <p className="item-desc">
              Molho, muçarela, calabresa fatiada, rodelas de tomate, catupiry,
              orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 41,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 59,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 76,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Tropeiro</h4>

            <p className="item-desc">
              Molho, carne seca, bacon, cebola, calabresa fatiada, muçarela,
              catupiry, orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 46,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 59,50
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 85,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Vegetariana</h4>

            <p className="item-desc">
              Molho, milho, brocólis, champignon, muçarela, tomate, catupiry,
              orégano.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 38,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 51,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 66,50
              </h4>
            </div>
          </Item>
        </Menu>

        <Menu className="menu">
          <h1 className="menu-title">Pizzas Doce</h1>

          {/* <Item>
            <h4 className="item-title">Brigadeiro com Confete</h4>

            <p className="item-desc">Brigadeiro, confete.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 36,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 50,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 69,50
              </h4>
            </div>
          </Item> */}

          <Item>
            <h4 className="item-title">Chocolate</h4>

            <p className="item-desc">Chocolate derretido.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 36,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 50,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 66,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Chocolate com Confete</h4>

            <p className="item-desc">Chocolate derretido, confete.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 37,50
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 51,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 80,50
              </h4>
            </div>
          </Item>

          {/* <Item>
            <h4 className="item-title">
              Chocolate com Morango <span>Novidade</span>
            </h4>

            <p className="item-desc">chocolate derretido, morango.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 38,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 55,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 84,00
              </h4>
            </div>
          </Item> */}

          <Item>
            <h4 className="item-title">
              Chocolate com Ouro Branco <span>Novidade</span>
            </h4>

            <p className="item-desc">
              Chocolate derretido, bombom ouro branco.
            </p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 38,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 55,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 84,00
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              Doce de Leite <span>Novidade</span>
            </h4>

            <p className="item-desc">Doce de leite cremoso.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 37,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 50,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 66,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">Mesclada</h4>

            <p className="item-desc">Chocolate ao leite, chocolate branco.</p>

            <div className="item-price-wrraper">
              <h4 className="item-price">
                <span>6 pedaços</span> 38,00
              </h4>
              <h4 className="item-price">
                <span>8 pedaços</span> 51,00
              </h4>
              <h4 className="item-price">
                <span>12 pedaços</span> 70,50
              </h4>
            </div>
          </Item>

          <Item>
            <h4 className="item-title">
              <PizzaSlice size={26} /> &nbsp; Todas bordas das pizzas doces são
              recheadas com doce de leite.
            </h4>
          </Item>
        </Menu>

        <Esfihas />

        <Menu className="menu">
          <h1 className="menu-title">Bebidas</h1>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Cervejas</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Refrigerantes</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Água (com gás e sem gás)</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Sucos</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Bebidas Quentes</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Vodka</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Cachaça</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Whisky</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Vinhos</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Jurupinga</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Catuaba Selvagem</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Energético</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Americana</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Produtos de Mercearia</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Chocolates</h4>
            </div>
          </Item>

          <Item margin15>
            <div className="item-separator">
              <h4 className="item-title">Carvão</h4>
            </div>
          </Item>

          <Item margin15>
            <h4 className="item-title">
              <Beer size={26} /> &nbsp; Entre em contato para mais opções de
              bebidas.
            </h4>
          </Item>
        </Menu>
      </SwipeableViews>
    </Layout>
  );
};

export default IndexPage;
